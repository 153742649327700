import Vue from "vue";

import Vuetify, {
  VCombobox,
  VTextField,
} from "vuetify/lib";

Vue.use(Vuetify, {
  components: {
    VTextField,
    VCombobox,
  },
});

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: "xs", // This is equivalent to a value of 960
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#2C5CFA",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
});
