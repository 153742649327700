export class Place {
  constructor(place) {
    Object.assign(this, place);
    this.guideLink = getGuideLink(place);
    this.primaryPhone = getPrimaryPhone(place);
    this.images = (place.images || []).map((x) => ({
      ...x,
      link: replaceLink(x.link),
    }));
    this.background = getBackground(place);
    if (!this.place) {
      this.place = { id: place.placeTypeId };
    }
  }
}
function replaceLink(link) {
  if (link.includes(process.env.VUE_APP_STORAGE_URL)) return link;
  return `${process.env.VUE_APP_STORAGE_URL}${link}`;
}
function getBackground(place) {
  if (!place) return;
  if (place.background) return replaceLink(place.background);
  if (place.images && place.images.length > 0) {
    return replaceLink(place.images[0].link);
  }
}
export function getGuideLink(place) {
  if (!place) return;
  if (place.guideLink) return place.guideLink;
  return `https://www.google.com/maps/dir/?api=1&destination=${place.lat},${place.lng}`;
}

export function getPrimaryPhone(place) {
  if (!place) return;
  if (!place.contacts || place.contacts.length < 0) return;
  let phone = place.contacts.find((x) => x.type == "phone") || {};
  return phone.value;
}
