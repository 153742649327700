import localForage from "localforage";
const ONEDAYMS = 1 * 24 * 60 * 60 * 1000;
const DEFAULT_OPTION = {
  expires: 1,
  key: "default",
};
export class Storage {
  async getItem(key) {
    key = `storage_${key}`;
    const item = await localForage.getItem(key);
    if (!item) {
      return null;
    }
    const now = new Date();
    if (now.getTime() > item.expiry) {
      this.removeItem(key);
      return null;
    }
    return item.value;
  }
  async setItem(key, value, option = DEFAULT_OPTION) {
    key = `storage_${key}`;
    if (option && option.key) {
      if (!this._lastKeyStorage[option.key])
        this._lastKeyStorage[option.key] = [];
      if (this._lastKeyStorage[option.key].length > 2) {
        this.removeItem(this._lastKeyStorage[option.key][0]);
        this._lastKeyStorage[option.key].splice(0, 1);
      }
      this._lastKeyStorage[option.key].push(key);
    }
    option = Object.assign({}, DEFAULT_OPTION, option);
    const now = new Date();

    const item = {
      value: value,
      expiry: now.getTime() + option.expires * ONEDAYMS,
    };
    return localForage.setItem(key, item);
  }
  removeItem(key) {
    key = `storage_${key}`;
    return localForage.removeItem(key);
  }
  async removeAll(keyRemove = "") {
    const keys = await localForage.keys();
    const regex = new RegExp(/^storage_/);
    for (const key of keys) {
      if (key.match(regex) && key.includes(keyRemove)) {
        localForage.removeItem(key);
      }
    }
  }
  async handleGetDataStorage(key, cb, option) {
    let items = await this.getItem(key);
    if (items) {
      return items;
    }
    const data = await cb();

    this.setItem(key, data, option);
    return data;
  }
  constructor() {
    this._lastKeyStorage = {};
  }
}
export default new Storage();
