export function parseQueryString(qs) {
  var e,
    a = /\+/g, // Regex for replacing addition symbol with a space
    r = /([^&;=]+)=?([^&;]*)/g,
    d = function (s) {
      return decodeURIComponent(s.replace(a, " "));
    },
    q = qs,
    urlParams = {};

  /* jshint ignore:start */
  while ((e = r.exec(q))) {
    urlParams[d(e[1])] = d(e[2]);
    if (!e[0].includes("=") && !e[2]) {
      urlParams[d(e[1])] = true;
    }
  }
  /* jshint ignore:end */

  return urlParams;
}
export function convertCenter(centerParams) {
  let center = null;
  if (typeof centerParams === "string") {
    center = centerParams.split(",");
    center = { lat: parseFloat(center[0]), lng: parseFloat(center[1]) };
  } else if (Array.isArray(centerParams)) {
    center = { lat: parseFloat(center[1]), lng: parseFloat(center[0]) };
  }
  return center;
}

export function round(num, fixed = 4) {
  return (
    Math.round((num + Number.EPSILON) * Math.pow(10, fixed)) /
    Math.pow(10, fixed)
  );
}
export const getUUIDv4 = function () {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};
export * from "./Location.js";
export * from "./Debounce";
