export default {
  namespaced: true,
  state: {
    params: {
      search: "",
      bbox: [],
      state: null,
      district: null,
      commune: null,
      isCheckMove: false,
    },
    search: "",
    items: [],
    isComplex: false,
    countFeatureLayer: {},
  },
  getters: {
    params: (state) => state.params,
    countFeatureLayer: (state) => state.countFeatureLayer,
    isComplex: (state) => state.isComplex,
    search: (state) => state.search,
  },
  mutations: {
    setParams(state, params) {
      state.params = Object.assign({}, state.params, params);
    },
    setLocation(state, { location }) {
      state.params = { location };
    },
    setSearch(state, search) {
      state.search = search;
    },
    setIsComplex(state, isComplex) {
      state.isComplex = isComplex;
    },
    setCountFeatureLayer(state, countFeatureLayer) {
      state.countFeatureLayer = countFeatureLayer;
    },
  },
  actions: {},
};
