import { isFunction } from "lodash";

export class LoadingContainer {
  pendingMap = new Map();
  containerMap = new Map();
  loadingMap = new Map();

  addContainer(keyContainer, cbSetLoading) {
    this.removeContainer(keyContainer);
    if (!this.containerMap.has(keyContainer)) {
      // If there is no current request in container, add it
      this.containerMap.set(keyContainer, {
        cbSetLoading,
        items: new Map(),
      });
    }
  }
  setLoadingContainer(keyContainer) {
    if (!this.containerMap.has(keyContainer)) {
      return;
    }
    const { cbSetLoading, items } = this.containerMap.get(keyContainer);
    let loading = items.size > 0;
    let cb = this.loadingMap.get(keyContainer);
    if (cb) clearTimeout(cb);
    if (!loading) {
      cb = setTimeout(() => {
        cbSetLoading && isFunction(cbSetLoading) && cbSetLoading(loading);
        this.loadingMap.delete(keyContainer);
        clearTimeout(cb);
      }, 1500);
      this.loadingMap.set(keyContainer, cb);
    } else {
      cbSetLoading && isFunction(cbSetLoading) && cbSetLoading(loading);
    }
  }
  removeContainer(keyContainer) {
    if (!this.containerMap.has(keyContainer)) {
      return;
    }
    this.setLoadingContainer(keyContainer, false);
    this.containerMap.delete(keyContainer);
  }
  addPendingToContainer(keyContainer, keyPending) {
    if (!this.containerMap.has(keyContainer)) {
      return;
    }
    const container = this.containerMap.get(keyContainer);
    container.items.set(keyPending);
    this.containerMap.set(keyContainer, container);
    this.setLoadingContainer(keyContainer);
  }
  removePendingFromContainer(keyContainer, keyPending) {
    if (!this.containerMap.has(keyContainer)) {
      return;
    }
    const container = this.containerMap.get(keyContainer);
    container.items.delete(keyPending);
    this.containerMap.set(keyContainer, container);
    this.setLoadingContainer(keyContainer);
  }
  addPending(keyPending, keyContainer) {
    if (!this.pendingMap.has(keyPending)) {
      this.pendingMap.set(keyPending, { keyContainer });
      this.addPendingToContainer(keyContainer, keyPending);
    }
  }
  removePending(keyPending) {
    if (!this.pendingMap.has(keyPending)) {
      return;
    }
    const { keyContainer } = this.pendingMap.get(keyPending);
    this.removePendingFromContainer(keyContainer, keyPending);
    this.pendingMap.delete(keyPending);
  }
}
