import { getToken } from "@/service/auth";
import merge from "lodash.merge";
import { ContentTypeEnum, VAxios } from "./axios";

const baseURL = process.env.VUE_APP_API_BASE_URL;
/**
 * @type {AxiosTransform}
 */
const transform = {
  beforeRequestHook: (config, options) => {
    const { urlPrefix, joinPrefix, withoutToken } = options;
    const { url } = config;

    if (joinPrefix) {
      config.url = `${urlPrefix}/${url}`;
    }
    if (!withoutToken) {
      const token = getToken();
      if (!config.params) {
        config.params = {};
      }
      config.params.key = token;
    }
    return config;
  },
};
/**
 *
 * @param {CreateAxiosOptions} opt
 * @returns {VAxios}
 */
function createAxios(opt) {
  return new VAxios(
    merge(
      {
        // See https://developer.mozilla.org/en-US/docs/Web/HTTP/Authentication#authentication_schemes
        // authentication schemes，e.g: Bearer
        // authenticationScheme: 'Bearer',
        authenticationScheme: "Bearer",
        timeout: 10 * 1000,
        headers: { "Content-Type": ContentTypeEnum.JSON },
        transform,
        requestOptions: {
          joinPrefix: true,
          ignoreCancelToken: false,
          withoutToken: false,
        },
      },
      opt || {}
    )
  );
}
export const sdk = createAxios({
  baseURL: baseURL,
  headers: {
    "X-LOCALIZATION": "en",
    "Content-Type": ContentTypeEnum.JSON,
    Accept: ContentTypeEnum.JSON,
  },
  requestOptions: { urlPrefix: "api" },
});
