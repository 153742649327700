export class Organization {
  constructor(place) {
    Object.assign(this, place);
    this.logo = replaceLink(place.logo);
  }
}
function replaceLink(link) {
  if (link.includes(process.env.VUE_APP_STORAGE_URL)) return link;
  return `${process.env.VUE_APP_STORAGE_URL}/storage/${link}`;
}
