import { Place, getPrimaryPhone } from "@/service/model/Place";

export default {
  namespaced: true,
  state: {
    layers: {},
    place: {},
    itemArray: [],
    itemObject: {},
    layerItems: {}
  },
  getters: {
    layers: (state) => state.layers,
    items: (state) => state.itemArray.map((x) => state.itemObject[x]),
    itemsLayer: (state) => layerPlaceId => state.layerItems[layerPlaceId].map((x) => state.itemObject[x]),
    itemSearch: (state) => (ids) => ids.map((x) => state.itemObject[x]),
    place: (state) => state.place,
    urlLogo: (state) => (state.place ? state.place.logo : ""),
  },
  mutations: {
    setItems(state, items) {
      let itemsObject = {},
        itemsArray = [], layerItems = {};
      let layers = items.reduce((acc, cur) => {
        cur = Object.assign({}, cur);
        cur.features_count = cur.places.length;
        layerItems[cur.placeTypeId] = []
        cur.places.forEach((item) => {
          item.place = cur;
          item.placeTypeId = cur.placeTypeId;
          item.primaryPhone = getPrimaryPhone(item);
          let id = `${cur.placeTypeId}-${item.id}`;
          itemsObject[id] = new Place(item);
          itemsArray.push(id);
          layerItems[cur.placeTypeId].push(id)
        });
        delete cur.places
        acc[cur.placeTypeId] = Object.assign({}, cur);
        return acc;
      }, {});
      state.layerItems = layerItems
      state.layers = layers;
      state.itemArray = itemsArray;
      state.itemObject = itemsObject;
    },
    setSearch(state, search) {
      state.search = search;
    },
    setPlace(state, place) {
      state.place = place;
    },
  },
  actions: {},
};
