import { convertCenter, parseQueryString, round } from "@/utils";

import { API_LOCATION } from "../api";
import { getLocation } from "@/utils";

export class UrlParams {
  constructor() {
    this.params = {};
    this._center = { lat: 20.8494022, lng: 105.85121154785156 };
    this.defaultCenter = { lat: 20.8494022, lng: 105.85121154785156 };
    this.defaultZoom = 14;
    this.paramsData = null;
  }
  async initLocation(cb) {
    if (!cb) {
      return;
    }
    try {
      let location = await getLocation();
      if (location) {
        this._location = {
          lat: location.latitude,
          lng: location.longitude,
        };
        cb(this._location);
      }
    } catch (err) {
      console.error(err);
    }
  }
  async initData(strParam) {
    let params = {};
    if (strParam) params = parseQueryString(strParam);
    this.params = params;

    this.paramsData = null;
    if (this.params["data"]) {
      try {
        this.paramsData = JSON.parse(window.atob(this.params["data"]));
      } catch (error) {
        console.error(error);
        this.paramsData = null;
      }
    }
    this.zoom = params.z ? parseFloat(params.z) : this.defaultZoom;
    if (!params.c) {
      if (this._location && this._location.lat && this._location.lng) {
        this._center = {
          lat: this._location.lat,
          lng: this._location.lng,
        };
        this.zoom = this.defaultZoom;
      } else {
        let { center } = await API_LOCATION();
        if (center) {
          this._center = center;
        } else this._center = this.defaultCenter;
      }
      this._isNoCenterInit = true;
    } else {
      this._center = convertCenter(params.c);
    }

    return this;
  }
  get isNoCenterInit() {
    return this._isNoCenterInit;
  }
  get location() {
    return this._location;
  }
  get key() {
    return this.params["key"];
  }
  get isDisableSearch() {
    return this.params["disable-search"];
  }
  get isDisableTool() {
    return this.params["disable-tool"];
  }
  get isIframe() {
    return this.params["disable-tool"];
  }
  get isDisableMarket() {
    return this.params["iframe"];
  }
  get usePopup() {
    return this.params["use-popup"];
  }
  get useBox() {
    return this.params["use-box"];
  }
  get data() {
    return this.paramsData;
  }
  set data(value = {}) {
    this.paramsData = value;
  }
  get center() {
    return [this._center.lng, this._center.lat];
  }
  set center({ lat, lng } = {}) {
    if ((lat, lng)) {
      this._center = { lat, lng };
    } else {
      this._center = null;
    }
  }
  get zoom() {
    return this.params.z || this.defaultZoom;
  }
  get map() {
    return this.params.map || 'mapbox';
  }
  set zoom(value) {
    this.params.z = value;
  }
  updateQueryUrl(params) {
    params = { ...this.params, ...params };

    let keyAccepted = [
      "disable-market",
      "use-box",
      "use-popup",
      "iframe",
      "disable-tool",
      "disable-search",
      "key",
      'map'
    ];
    Object.keys(params).forEach((key) => {
      let index = keyAccepted.findIndex((x) => x === key);
      if (index < 0) {
        delete params[key];
      } else {
        keyAccepted.splice(index, 1);
      }
    });
    params.c = [round(+this._center.lat, 7), round(+this._center.lng, 7)];
    params.z = this.zoom;
    if (this.data) {
      try {
        params.data = window.btoa(JSON.stringify(this.data));
      } catch (err) {
        console.error(err);
      }
    }
    // * NOTE: de key luon o sau cung
    if (params.key) {
      let key = params.key;
      delete params.key;
      params.key = key;
    }

    const query = encodeQueryData(params);
    let newUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      query;
    window.history.replaceState({ path: newUrl }, "", newUrl);
  }
}

export function encodeQueryData(data) {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
  return ret.join("&");
}
