import camelCase from "lodash/camelCase";

const requireModule = require.context(
  // The relative path of the components folder
  ".",
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /w*\/store-(\w+).js$/
);
const modules = [];
requireModule.keys().forEach((fileName) => {
  const moduleName = camelCase(
    fileName
      .substring(fileName.indexOf("store-") + 5, fileName.lastIndexOf("."))

      .replace(/(\.\/|\.js)/g, "")
  );
  modules[moduleName] = {
    namespaced: true,
    ...requireModule(fileName).default
  };
});
export default modules;
