import { parseQueryString } from "@/utils";
let token = "";

export function getToken() {
  if (!token) {
    let params = parseQueryString(window.location.search.substr(1));
    token = params.key;
  }
  return token;
}
