<template>
  <v-app class="overflow-hidden main-container">
    <div class="pace" v-show="loadingBar">
      <div class="pace-progress">
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      </div>
      <div class="pace-activity"></div>
    </div>
    <v-main v-if="!isError">
      <MapWithKey :initOptions="initOptions" :option="option" />
    </v-main>
    <v-main v-else-if="error.isMissingKey">
      <div class="d-flex full-height">
        <div class="flex-grow-1 d-flex flex-column">
          <MapNoKey
            class="flex-grow-1"
            :initOptions="initOptions"
            :option="option"
          ></MapNoKey>
        </div>
      </div>
    </v-main>
    <v-overlay v-else :value="true" class="text-center pa-4">
      <div class="text-h4" v-if="error.isMissingKey">Thiếu key</div>
      <div class="text-h4" v-if="error.isWrongKey">Key không đúng</div>
      <div class="text-h4" v-if="error.isError">
        Có vấn đề xảy ra với hệ thống, xin truy cập lại sau
      </div>
    </v-overlay>
    <notifications position="bottom center" :max="1" />
  </v-app>
</template>

<script>
import { UrlParams } from "@service/model/UrlParams";
import { loadingContainer } from "@service/loading";
import { getConfig } from "./utils/config";
import { loadLanguageAsync, setI18nLanguage } from "./plugins/i18n";
import { checkCurrentLang } from "./plugins/i18n";
export default {
  name: "App",
  components: {
    MapNoKey: () =>
      import(/* webpackChunkName: "no-key-map" */ "@/views/Map/MapNoKey.vue"),
    MapWithKey: () =>
      import(/* webpackChunkName: "no-key-map" */ "@/views/Map/MapWithKey.vue"),
  },
  async created() {
    loadingContainer.addContainer("global", (loading) => {
      this.loadingBar = loading;
    });
    this.params = new UrlParams();
    await this.params.initData(window.location.search.substr(1));
    this.onHandleParams();
    if (!this.params.key) {
      this.error.isMissingKey = true;
      return;
    }
  },
  data: () => ({
    loadingBar: false,
    initOptions: {
      center: getConfig("center", [105.85121154785156, 21.02682813712425]),
      zoom: getConfig("zoom", 8),
      maxZoom: 22,
      attributionControl: false,
      zoomControl: false,
    },
    option: {
      isShowSearch: true,
      isShowTool: true,
      isShowPopup: false,
      isShowBoxResult: false,
      isShowMarket: true,
    },
    loading: false,
    error: { isMissingKey: false, isWrongKey: false, isError: false },
    params: {},
  }),
  computed: {
    isError() {
      return Object.values(this.error).some((x) => x);
    },
  },
  methods: {
    onHandleParams() {
      this.initOptions.center = this.params.center;
      this.initOptions.zoom = this.params.zoom;
      let lang = this.params.params.lang;
      if (lang) {
        loadLanguageAsync(lang);
      } else {
        checkCurrentLang();
      }
      if (this.params.isDisableSearch) {
        this.option.isShowSearch = false;
      }
      if (this.params.isDisableTool) {
        this.option.isShowTool = false;
      }
      if (this.params.isIframe) {
        this.option.isShowPopup = true;
        this.option.isShowTool = false;
        this.option.isShowSearch = false;
        this.option.isShowMarket = false;
      }
      if (this.params.usePopup) {
        this.option.isShowPopup = true;
        this.option.isShowMarket = false;
      }
      if (this.params.useBox) {
        this.option.isShowPopup = false;
        this.option.isShowBoxResult = true;
        this.option.isShowSearch = false;
      }
      if (this.params.isDisableMarket) {
        this.option.isShowMarket = false;
      }
    },
  },
};
</script>
<style></style>
